import { API } from "../app.config";
import auth from "./auth";

const formatError = (error) => ({
  error: true,
  data: [
    {
      messages: [
        {
          message: error,
        },
      ],
    },
  ],
});

function checkResponse(response) {
  if (response.status === 401 && auth.get()) {
    auth.remove();
    setTimeout(() => window.location.assign("/login"), 1000);
    throw new Error("Authentication expired. Please login.");
  }

  if (response.status === 429) {
    throw new Error("To many requests, retry in 10 minutes.");
  }

  return response.json();
}

export const login = async (credentials) => {
  const url = `${API}/auth/local`;
  const options = {
    method: "POST",
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    body: JSON.stringify(credentials),
  };
  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return formatError(error.message);
  }
};

export const checkAuth = async (jwt) => {
  const url = `${API}/users/me`;
  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return formatError(error.message);
  }
};

export const register = async (user) => {
  const url = `${API}/auth/local/register`;
  const options = {
    method: "POST",
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const getSetup = async () => {
  const urls = [`${API}/summit-setup`];

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await Promise.all(
      urls.map((url) => fetch(url, options).then(checkResponse))
    );
  } catch (error) {
    return { error: error.message };
  }
};

export const updateUserProfile = async (jwt, userID, userProfile) => {
  const url = `${API}/users/${userID}`;

  const options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userProfile),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const getProducts = async (jwt) => {
  const urls = [`${API}/products`, `${API}/discounts`];

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  try {
    return await Promise.all(
      urls.map((url) => fetch(url, options).then(checkResponse))
    );
  } catch (error) {
    return { error: error.message };
  }
};

export const requestProduct = async (jwt, product) => {
  const url = `${API}/checkout/request`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(product),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const checkout = async (jwt, billingData) => {
  const url = `${API}/checkout`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(billingData),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const purchase = async (jwt, order) => {
  const url = `${API}/checkout/payment`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(order),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const getActiveTickets = async (jwt) => {
  const url = `${API}/tickets`;

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const activateTicket = async (jwt, ticket) => {
  const url = `${API}/tickets/activate`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ticket),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const deactivateTicket = async (jwt, ticket) => {
  const url = `${API}/tickets/deactivate`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ticket),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const getTickets = async (jwt) => {
  const url = `${API}/tickets/all`;

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const transferTicket = async (jwt, data) => {
  const url = `${API}/tickets/transfer`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const removeTicket = async (jwt, data) => {
  const url = `${API}/tickets/remove`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const getParticipants = async (
  jwt,
  skip = 0,
  limit = 0,
  industryRole
) => {
  const url = `${API}/participants?skip=${skip}&limit=${limit}&industryRole=${
    industryRole ? industryRole : ""
  }`;

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const searchParticipants = async (jwt, data, skip = 0, limit = 0) => {
  const url = `${API}/participants?skip=${skip}&limit=${limit}`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const makeReservation = async (jwt, data) => {
  const url = `${API}/reservations`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const removeReservation = async (jwt, eventID) => {
  const url = `${API}/reservations/${eventID}`;

  const options = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const getVoteData = async (jwt) => {
  const url = `${API}/vote`;

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const saveProposal = async (jwt, proposal) => {
  const url = `${API}/proposals`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(proposal),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const voteNominee = async (jwt, nominee) => {
  const url = `${API}/vote`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(nominee),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const getMeetingData = async (jwt) => {
  const urls = [`${API}/participants?skip=0&limit=0`, `${API}/meetings-setup`];

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await Promise.all(
      urls.map((url) => fetch(url, options).then(checkResponse))
    );
  } catch (error) {
    return { error: error.message };
  }
};

export const getAvailableRooms = async (jwt, data) => {
  const url = `${API}/meetings/availability`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const createMeeting = async (jwt, meeting) => {
  const url = `${API}/meetings`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(meeting),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const updateMeetingStatus = async (jwt, meeting) => {
  const url = `${API}/meetings/update`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(meeting),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const getScheduleData = async (jwt) => {
  const urls = [`${API}/events`, `${API}/reservations`, `${API}/meetings`];

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await Promise.all(
      urls.map((url) => fetch(url, options).then(checkResponse))
    );
  } catch (error) {
    return { error: error.message };
  }
};

export const getMessages = async (jwt) => {
  const url = `${API}/app-messages`;

  const options = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const updateMessages = async (jwt, data) => {
  const url = `${API}/app-messages`;

  const options = {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const sendMessage = async (jwt, message) => {
  const url = `${API}/app-messages`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const getNewMessages = async (jwt, data) => {
  const url = `${API}/app-messages/getNewMessages`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const loadMoreMessages = async (jwt, data) => {
  const url = `${API}/app-messages/loadMore`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const searchMessages = async (jwt, data) => {
  const url = `${API}/app-messages/search`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export const getVatRate = async (jwt, data) => {
  const url = `${API}/vat`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
};

export async function saveSubscriptionInDB(jwt, subscription, settings) {
  const url = `${API}/push-subscriptions${
    settings && settings.login ? "?login=true" : ""
  }`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(subscription),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
}

export async function deleteSubscriptionFromDB(jwt, subscription) {
  const url = `${API}/push-subscriptions/delete`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(subscription),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
}

export async function getPaymentIntent(jwt, order) {
  const url = `${API}/checkout/payment-intent`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(order),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
}

export async function payment(jwt, order) {
  const url = `${API}/checkout/payment`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(order),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
}

export async function revolutCreateOrder(jwt, order) {
  const url = `${API}/checkout/revolut-order`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(order),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
}

export async function bitpayCreateOrder(jwt, order) {
  const url = `${API}/checkout/bitpay-order`;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(order),
  };

  try {
    return await fetch(url, options).then(checkResponse);
  } catch (error) {
    return { error: error.message };
  }
}
