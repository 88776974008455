import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CartContext } from "../Cart";
import { AuthContext } from "../Auth";
import { SummitContext } from "../../App";
import CountrySelector from "../CountrySelector";
import { checkout, getProducts, getVatRate } from "../../actions/api";
import { Payment, InfoOutlined, ArrowBack } from "@material-ui/icons";
import {
  IconButton,
  Typography,
  Slide,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Dialog,
  DialogContent,
  Button,
  Zoom,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

const calculatePrice = (vatRate, cart) => {
  const subtotal = cart.reduce((acc, entry) => {
    const {
      product: { price },
      qty,
      discounts,
    } = entry;
    const totalDiscount = discounts.reduce((acc, cur) => acc + cur.amount, 0);
    const priceWithDiscounts = price - price * (totalDiscount / 100);
    const subTotal = priceWithDiscounts * qty;
    return acc + subTotal;
  }, 0);
  const vatAmount = subtotal * (vatRate / 100);
  return {
    subtotal: subtotal.toFixed(2),
    vatAmount: vatAmount.toFixed(2),
    amount: (subtotal + vatAmount).toFixed(2),
  };
};

const CheckoutSection = ({ order, setOrder, setLoading }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { jwt, user } = useContext(AuthContext);
  const { currency } = useContext(SummitContext);
  const {
    cart,
    cartDispatch,
    messages: cartMessages,
  } = useContext(CartContext);
  const [openDialog, setOpenDialog] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(
    order.paymentMethod || "card"
  );
  const [billingType, setBillingType] = useState(order.billingType || "person");
  const [billingInfo, setBillingInfo] = useState({
    firstName: order.billingInfo.firstName || "",
    lastName: order.billingInfo.lastName || "",
    company: order.billingInfo.company || "",
    address: order.billingInfo.address || "",
    zip: order.billingInfo.zip || "",
    city: order.billingInfo.city || "",
    country: order.billingInfo.country || "",
    vat: order.billingInfo.vat || "",
  });
  const [redirectToPayment, setRedirectToPayment] = useState(false);

  const { firstName, lastName, company, address, zip, city, country, vat } =
    billingInfo;

  useEffect(() => {
    if (cart.length === 0 && cartMessages.length === 0) history.push("/cart");
  }, [cart, history, cartMessages]);

  useEffect(() => {
    setOrder((order) => ({ ...order, status: null }));
  }, [setOrder]);

  useEffect(() => {
    if (redirectToPayment) {
      if (cartMessages.length > 0) {
        setLoading(false);
        setRedirectToPayment(false);
        setOpenDialog(true);
        return;
      }

      getVatRate(jwt, { billingType, country, vat }).then((data) => {
        if (data.error) {
          enqueueSnackbar(data.error, { variant: "error" });
          setLoading(false);
          return;
        }
        const { subtotal, vatAmount, amount } = calculatePrice(
          data.vatRate,
          cart
        );
        setOrder((order) => ({
          ...order,
          amount,
          vatAmount,
          subtotal,
          vatRate: data.vatRate,
        }));
        history.push("/cart/payment");
      });
    }
  }, [
    jwt,
    setOrder,
    redirectToPayment,
    cartMessages,
    history,
    cartDispatch,
    setLoading,
    billingType,
    country,
    vat,
    enqueueSnackbar,
    cart,
  ]);

  const handleFormChange = (field) => (event) => {
    setBillingInfo({ ...billingInfo, [field]: event.target.value });
  };

  const handleChange = (field) => (event) => {
    switch (field) {
      case "payment":
        setPaymentMethod(event.target.value);
        return;
      case "billing":
        if (event.target.value === "person")
          setBillingInfo({ ...billingInfo, company: "", vat: "" });
        if (event.target.value === "company")
          setBillingInfo({ ...billingInfo, firstName: "", lastName: "" });
        setBillingType(event.target.value);
        return;
      default:
        return;
    }
  };

  const handleDialogClose = () => {
    cartDispatch({ type: "clearMessages" });
    history.push("/cart");
  };

  const handleCheckout = async () => {
    setLoading(true);

    const billingData = { ...billingInfo, billingType, paymentMethod };

    if (billingType === "person") {
      billingData.company = "NA";
      billingData.vat = "NA";
    }
    if (billingType === "company") {
      billingData.firstName = "NA";
      billingData.lastName = "NA";
    }

    const response = await checkout(jwt, billingData);
    if (response.error) {
      enqueueSnackbar(response.error, { variant: "error" });
      setLoading(false);
      return;
    }

    const data = await getProducts(jwt);
    if (data.error) {
      enqueueSnackbar(data.error, { variant: "error" });
      setLoading(false);
      return;
    }
    cartDispatch({
      type: "updateCart",
      products: data[0],
      discounts: data[1],
      userID: user.id,
    });

    setOrder((order) => ({
      ...order,
      status: "OK",
      billingType,
      billingInfo,
      paymentMethod,
      cart,
      currency,
    }));

    setRedirectToPayment(true);
  };

  const showPaymentMethod = () => {
    return (
      <div className="mb-4">
        <p className="mb-2 text-center">PAYMENT METHOD</p>
        <RadioGroup
          row
          name="billingType"
          value={paymentMethod}
          className="justify-content-center rounded-top bg-white shadow py-2"
        >
          <FormControlLabel
            onChange={handleChange("payment")}
            className="ml-1"
            value="card"
            control={<Radio size="small" />}
            label="Card"
          />
          <FormControlLabel
              onChange={handleChange("payment")}
              className="ml-1"
              value="crypto"
              control={<Radio size="small" />}
              label="Crypto"
          />
          { false && (
            <>
              <FormControlLabel
                onChange={handleChange("payment")}
                className="ml-1"
                value="wire"
                control={<Radio size="small" />}
                label="Wire"
              />
            </>
          )}
        </RadioGroup>
      </div>
    );
  };

  const showBillingInfo = () => {
    return (
      <div className="mb-4">
        <div className="mb-2 text-center">BILLING INFO</div>
        <RadioGroup
          row
          name="billingType"
          value={billingType}
          className="justify-content-center border-top rounded-top bg-white shadow py-2 mb-3"
        >
          <FormControlLabel
            onChange={handleChange("billing")}
            className="ml-1"
            value="person"
            control={<Radio size="small" />}
            label="Person"
          />
          <FormControlLabel
            onChange={handleChange("billing")}
            className="ml-1"
            value="company"
            control={<Radio size="small" />}
            label="Company"
          />
        </RadioGroup>

        {billingType === "company" && (
          <>
            <TextField
              onChange={handleFormChange("company")}
              value={company}
              className="mb-3 shadow"
              label="Company name"
              variant="filled"
              fullWidth
            />
            <TextField
              onChange={handleFormChange("vat")}
              value={vat}
              className="mb-3 shadow"
              label="VAT number"
              variant="filled"
              fullWidth
            />
          </>
        )}

        {billingType === "person" && (
          <div>
            <TextField
              onChange={handleFormChange("firstName")}
              value={firstName}
              className="mb-3 shadow"
              label="First name"
              variant="filled"
              fullWidth
            />
            <TextField
              onChange={handleFormChange("lastName")}
              value={lastName}
              className="mb-3 shadow"
              label="Last name"
              variant="filled"
              fullWidth
            />
          </div>
        )}

        <TextField
          onChange={handleFormChange("address")}
          value={address}
          className="mb-3 shadow"
          label="Address"
          variant="filled"
          fullWidth
        />
        <TextField
          onChange={handleFormChange("zip")}
          value={zip}
          className="mb-3 shadow"
          label="Zip code"
          variant="filled"
          fullWidth
        />
        <TextField
          onChange={handleFormChange("city")}
          value={city}
          className="mb-3 shadow"
          label="City"
          variant="filled"
          fullWidth
        />
        <CountrySelector
          className="shadow"
          handleChange={handleFormChange("country")}
          value={country}
        />
      </div>
    );
  };

  return (
    <div className="content-max-width w-100 mx-auto p-3 footer-clearence">
      <Typography align="center" variant="h6" className="mb-3">
        CHECKOUT
      </Typography>

      {showBillingInfo()}
      {showPaymentMethod()}

      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleDialogClose}
        TransitionComponent={Zoom}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent className="p-0">
          <div className="p-3 text-center">
            <InfoOutlined className="mb-3" color="secondary" />
            <p className="mb-2">
              Some products or discounts have changed or are not available
              anymore.
            </p>
            <p> Please review your cart products.</p>
          </div>
          <Button
            className="py-3 border-top"
            size="large"
            onClick={handleDialogClose}
            color="secondary"
            fullWidth
          >
            {" "}
            BACK TO CART{" "}
          </Button>
        </DialogContent>
      </Dialog>

      <Slide in direction="up">
        <div className="footer d-flex justify-content-center align-items-center position-fixed bottom z-index-high bg-white shadow pb-4">
          <div
            className="d-flex flex-column align-items-center pointer"
            onClick={() => history.push("/cart")}
          >
            <IconButton color="secondary">
              <ArrowBack fontSize="large" />
            </IconButton>
            <Typography color="secondary" className="icon-label">
              BACK TO CART
            </Typography>
          </div>

          <div
            className="d-flex flex-column align-items-center pointer ml-4"
            onClick={handleCheckout}
          >
            <IconButton color="secondary">
              <Payment fontSize="large" />
            </IconButton>
            <Typography color="secondary" className="icon-label">
              REVIEW & PAY
            </Typography>
          </div>
        </div>
      </Slide>
    </div>
  );
};

export default CheckoutSection;
